import React from 'react';
import { NextSeo } from 'next-seo';
import SEO from '../next-seo.config';

import makeStyles from '@mui/styles/makeStyles';


import Header from 'components/MaterialKit/Header/Header.js';
import FooterLayout from 'layouts/FrontSubsection/FooterLayout';
import HeaderLinks from 'layouts/FrontSubsection/HeaderLinks';

import landingPageStyle from 'assets/jss/nextjs-material-kit-pro/pages/landingPageStyle.js';

// Sections for this page


const useStyles = makeStyles(landingPageStyle);

const FrontSubsection = ({children, ...rest}) =>{
    const classes = useStyles();

    return(
        <div>
            <NextSeo {...SEO} />
            <Header
                color="white"
                brand="Tailings Monitoring"
                brandImage ={<>
                    <img src={require('assets/img/amira.svg')} alt={'amira-logo'} style={{height:'30px', margin:'5px'}}/>
                    <img src={require('assets/img/uwa.png')} alt={'uwa-logo'} style={{height:'30px', margin:'5px'}}/>
                </>}
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                changeColorOnScroll={{
                    height: 300,
                    color: 'white',
                }}
                {...rest}
            />
            {children}
            <FooterLayout />
        </div>
    );
};

export default FrontSubsection;