import React from 'react';
// nodejs library to set properties for components
import Link from 'next/link';

import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import Footer from 'components/MaterialKit/Footer/Footer.js';
import GridContainer from 'components/MaterialKit/Grid/GridContainer.js';
import GridItem from 'components/MaterialKit/Grid/GridItem.js';

import styles from 'assets/jss/nextjs-material-kit-pro/pages/componentsSections/footerStyle.js';

const useStyles = makeStyles(styles);

const FooterLayout = () => {
    const classes = useStyles();

    return (
        <Footer
            theme="white"
            content={
                <div>
                    <div className={classNames(classes.pullCenter, classes.copyRight)}>
            &copy; {1900 + new Date().getYear()} Made by{' '}
                        <a
                            href="https://frinzelapuz.vercel.app"
                            target="_blank"
                            rel="noreferrer"
                        >
              Frinze Erin Lapuz
                        </a>
                    </div>
                </div>
            }
        >
            <div className={classes.footer}>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={4} md={4}>
                        <h5>
                            <Link href="/">
                                <a>Tailings Monitoring</a>
                            </Link>
                        </h5>
                        <p>
                            Meaningful monitoring of Tailings Storage Facilities
                        </p>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} style={{textAlign:'center'}}>
                        <h5>
                            <Link href="#">
                                <a>Home</a>
                            </Link>
                        </h5>
                        <ul className={classes.linksVertical}>
                            <li>
                                <Link href="/about-us">
                                    <a>About us</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/about-us#the-project">
                                    <a>The Project</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/about-us#sponsors">
                                    <a>Sponsors</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/about-us#the-team">
                                    <a>The Team</a>
                                </Link>
                            </li>
                        </ul>
                    </GridItem>
                    {/* <GridItem xs={12} sm={2} md={2}>
                        <h5>
                            <Link href="/news">
                                <a>Newsfeed</a>
                            </Link>
                        </h5>
                        <ul className={classes.linksVertical}>
                            <li>
                                <Link href="/news">
                                    <a>News 1</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/news">
                                    <a>More News</a>
                                </Link>
                            </li>
                        </ul>
                    </GridItem> */}
                    <GridItem xs={12} sm={4} md={4} style={{textAlign:'center'}}>
                        <h5>
                            <Link href="/login">
                                <a>Login</a>
                            </Link>
                        </h5>
                        <ul className={classes.linksVertical}>
                            <li>
                                <Link href="/privacy-policy">
                                    <a>Privacy Policy</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/contact-us">
                                    <a>Contact Us</a>
                                </Link>
                            </li>
                        </ul>
                    </GridItem>
                    {/* <GridItem xs={12} sm={3} md={34>
    4                   <h5>Follow us</h5>
                        <p>Follow us on our social media to get the latest updates</p>
                        <Button color="twitter" justIcon>
                            <i className="fab fa-twitter" />
                        </Button>
                        <Button color="google" justIcon>
                            <i className="fab fa-google" />
                        </Button>
                        <Button color="linkedin" justIcon>
                            <i className="fab fa-linkedin-in" />
                        </Button>
                        <Button color="youtube" justIcon>
                            <i className="fab fa-youtube" />
                        </Button>
                    </GridItem> */}
                </GridContainer>
            </div>
        </Footer>
    );
};

export default FooterLayout;
