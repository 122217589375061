export default {
    title: 'Tailings Monitoring',
    description:
    'We are a team of geotechnical engineers integrating soils laboratory element testing, numerical modelling, and centrifuge testing with real-life monitoring data to provide meaningful monitoring solutions to the mining industry. We are based at the University of Western Australia in the School of Engineering. With the financial support and technological help from a range of monitoring technologies specialists, we bridge the knowledge gap between monitoring measurements and the meaningful interpretation of the data.',
    canonical: 'https://tailingsmonitoring.systemhealthlab.com/',
    author: 'Frinze Erin Lapuz',
    openGraph: {
        type: 'website',
        locale: 'en_au',
        url: 'https://tailingsmonitoring.systemhealthlab.com/',
        site_name: 'Tailings Monitoring',
        title: 'Tailings Monitoring',
        images: [
            {
                url: 'https://tailingsmonitoring.systemhealthlab.com/img/android-chrome-512x512.png',
                width: 512,
                height: 512,
                alt: 'Og Image Alt',
            },
            {
                url: 'https://tailingsmonitoring.systemhealthlab.com/img/android-chrome-192x192.png',
                width: 192,
                height: 192,
                alt: 'Og Image Alt Second',
            },
        ],
    },
};
