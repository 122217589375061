import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Button from 'components/MaterialKit/CustomButtons/Button.js';

import styles from 'assets/jss/nextjs-material-kit-pro/components/headerLinksStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

    const classes = useStyles();
    return (
        <List className={classes.list + ' ' + classes.mlAuto}>
            <ListItem className={classes.listItem}>
                <Button
                    href="/"
                    className={classes.navLink}
                    color="transparent"
                >
                    <HomeIcon className={classes.buttonIcon} /> Home
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    href="/about-us"
                    className={classes.navLink}
                    color="transparent"
                >
                    <InfoIcon className={classes.buttonIcon} /> About Us
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    href="/contact-us"
                    className={classes.navLink}
                    color="transparent"
                >
                    <MailIcon className={classes.buttonIcon} /> Contact Us
                </Button>
            </ListItem>
            {/* <ListItem className={classes.listItem}>
                <Button
                    href="/news"
                    className={classes.navLink}
                    color="transparent"
                >
                    <AnnouncementIcon className={classes.buttonIcon} /> News
                </Button>
            </ListItem> */}
            <ListItem className={classes.listItem}>
                <Button
                    href="/login"
                    color={'rose'}
                    className={classes.navButton}
                    round
                >
                    <DashboardIcon className={classes.icons} /> Login
                </Button>
            </ListItem>
        </List>
    );
}

HeaderLinks.defaultProps = {
    hoverColor: 'primary',
};

HeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf([
        'dark',
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'rose',
    ]),
};
